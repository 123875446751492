<template>
  <div class="content-wrap" style="background: #fff">
    <div class="wrap">
      <header class="pt-32">
        <img class="logo_img mb-16" src="@/assets/image/medal_logo_green.png" />
        <div class="fm-12 f-g40 mb-16">매달 파트너 가입 신청</div>
        <div class="navBar mb-32">
          <ul>
            <li class="ml-16 fm-14 nav-selected">1.개인 정보 입력</li>
            <li class="ml-16 fm-14">2.분야 입력</li>
            <li class="ml-16 fm-14">3.가입동의</li>
            <li class="ml-16 fm-14">4.가입신청 완료</li>
          </ul>
        </div>
      </header>
      <div class="contentBox">
        <div class="title_name mb-16">
          <span class="fm-16">파트너 가입자 본인인증</span>
          <p class="fr-12 mt-8">
            파트너 가입자의 은행 계좌번호로 본인인증 및 가입 여부를 확인하고
            있습니다.
          </p>
          <p class="fr-12">
            입력하신 개인정보 및 계좌번호는 가입완료 전까지 저장되지 않습니다.
          </p>
        </div>
        <ul class="my_information">
          <li class="li-wrap">
            <span class="address-title">이름</span>
            <div
              class="flex-space-between flex-align-center"
              style="width: 100%"
            >
              <span class="address-value"
                ><input type="text" v-model="sendParams.name"
              /></span>
              <span class="business-info">가입자 실명을 입력해 주세요.</span>
            </div>
          </li>
          <li class="li-wrap">
            <span class="address-title">생년월일</span>
            <div
              class="flex-space-between flex-align-center"
              style="width: 100%"
            >
              <div class="address-value flex-align-center">
                <input
                  type="text"
                  :maxlength="6"
                  style="width: 80px"
                  v-model="sendParams.identityNum"
                />
                <span style="margin: 0 6px"> - </span>
                <input
                  type="text"
                  :maxlength="1"
                  style="width: 30px"
                  v-model="gender"
                />
                <div class="div-circle" />
                <div class="div-circle" />
                <div class="div-circle" />
                <div class="div-circle" />
                <div class="div-circle" />
                <div class="div-circle" />
              </div>
              <span class="business-info"
                >생년월일과 주민등록 번호 뒤<br />
                첫째자리를 입력해 주세요.</span
              >
            </div>
          </li>
          <li class="li-wrap">
            <span class="address-title">은행선택</span>
            <div
              class="flex-space-between flex-align-center"
              style="width: 100%"
            >
              <span class="address-value">
                <select-box
                  :opt-nm="SELT_POPBILL_BANK"
                  v-model="sendParams.bankCode"
                  style="width: 200px; text-align: left"
              /></span>
              <span class="business-info"
                >주거래 은행과 계좌번호를 입력해 주세요.</span
              >
            </div>
          </li>
          <li
            class="li-wrap"
            style="display: flex; border-bottom: 1px solid var(--gray20)"
          >
            <span class="address-title">계좌번호</span>
            <div
              class="flex-space-between flex-align-center"
              style="padding-left: 16px; width: 100%"
            >
              <div class="flex-align-center">
                <input
                  type="text"
                  class="mr-8"
                  style="width: 200px"
                  v-model="sendParams.accountNumber"
                />
                <button-common
                  :disabled="!checkValid"
                  :size="SIZE_T"
                  :clr="CLR_G"
                  @click="checkAccount"
                  >계좌인증</button-common
                >
              </div>
              <span
                class="business-info"
                style="color: #2ccdc3"
                v-if="!isFirst && isCheck"
                >인증완료</span
              >
              <span
                class="business-info"
                style="color: #fd765f"
                v-if="!isFirst && !isCheck"
                >인증실패</span
              >
            </div>
          </li>
        </ul>
        <div
          class="d-flex justify-content-center column"
          style="margin-top: 110px"
        >
          <div class="explanation-box fr-12 f-g60">
            입력하신 계좌번호는 가입승인 후 정산계좌로 자동등록되며 등록된
            파트너 정보는 변경이 가능합니다.
          </div>
        </div>
      </div>
    </div>
    <div class="next mb-80" style="margin-top: 62px">
      <button-common
        :size="SIZE_S"
        :clr="CLR_W"
        style="margin-right: 16px"
        @click="goBack()"
        >이전</button-common
      >
      <button-common
        :disabled="!isCheck"
        :size="SIZE_S"
        :clr="CLR_B"
        @click="regAccount()"
        >다음</button-common
      >
    </div>
  </div>
</template>
<script>
export default {
  name: "Business",
  components: {},
  data() {
    return {
      sendParams: {
        name: "",
        identityNumType: "P",
        identityNum: "",
        bankCode: "",
        accountNumber: "",
      },
      isFirst: true,
      isCheck: false,
      gender: "",
    };
  },
  computed: {
    checkValid() {
      return (
        this.sendParams.name &&
        this.sendParams.identityNum &&
        this.sendParams.bankCode &&
        this.sendParams.accountNumber
      );
    },
  },
  methods: {
    async checkAccount() {
      const rs = await this.$axios({
        url: "/partner/reg/checkDepositorInfo",
        params: this.sendParams,
      });
      this.isFirst = false;
      this.isCheck = false;
      if (rs.result === "SUC") {
        this.isCheck = rs.resultCode === 100;
        if (rs.resultCode !== 100) {
          await this.openError(rs.resultCode);
        }
      }

      if (rs.result === "DUPL") {
        await this.openDuplicate();
      }
    },
    async openError(code) {
      await this.$store.dispatch("A_ERROR_CODE", code);
      await this.$openError({}, this);
    },
    async openDuplicate() {
      await this.$openDuplicate({}, this);
    },
    // async openAddress() {
    //   await this.$openAddress(
    //     {
    //       cbPrmy: this.cbSelectAddress,
    //     },
    //     this
    //   );
    // },
    // cbSelectAddress(address) {
    //   this.roadAddress = address;
    // },
    async regAccount() {
      const data = {
        name: this.sendParams.name,
        birth: this.sendParams.identityNum,
        gender: this.gender,
        bankName: this.SELT_POPBILL_BANK.filter(
          (item) => item.value === this.sendParams.bankCode
        )[0].title,
        bankCode: this.sendParams.bankCode,
        accountNumber: this.sendParams.accountNumber,
      };
      const rs = await this.$axios({
        url: "/partner/reg/start",
        params: data,
      });
      this.isFirst = true;
      if (rs.result === "SUC") {
        this.goNextPage();
      }
      if (rs.result === "DUPL") {
        await this.openDuplicate();
      }
    },
    goNextPage() {
      this.$router.push({
        path: `/sign/phone`,
        meta: { id: "0-1-11" },
      });
    },
    goBack() {
      this.$router.back();
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/join";
.li-wrap {
  display: flex;
  align-items: center;
  padding: 10px 0 10px 12px;
}
.error {
  color: #f1431d;
  font-size: 14px;
}
.confirm {
  color: #2ec8b5;
  font-size: 14px;
}
.address-title {
  min-width: 200px;
  padding-left: 32px;
  font-size: 14px;
  font-weight: 500;
  color: #3a3d4d;
}
.address-value {
  padding-left: 16px;
  font-size: 16px;
  font-weight: 400;
  color: #696c80;
}
.business-info {
  font-weight: 500;
  font-size: 12px;
  color: #828699;
}
.div-circle {
  width: 12px;
  height: 12px;
  border-radius: 12px;
  background: #dddddd;
  margin: 0 2px;
}
</style>
